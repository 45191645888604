
    Object.defineProperty(exports, "__esModule", {
      value: true
    });

    module.exports = {
  "contentWidthDefault": "1280px",
  "contentWidthText": "768px",
  "contentWidthFull": "100%",
  "spacing2Xs": "0.25rem",
  "spacingXs": "0.5rem",
  "spacingS": "0.75rem",
  "spacingM": "1rem",
  "spacingL": "1.5rem",
  "spacingXl": "2rem",
  "spacing2Xl": "3rem",
  "spacing3Xl": "4rem",
  "spacing4Xl": "5rem",
  "zIndexNegative": -1,
  "zIndexWorkbench": 0,
  "zIndexDefault": 1,
  "zIndexWorkbenchHeader": 10,
  "zIndexModal": 100,
  "zIndexModalContent": 101,
  "zIndexDropdown": 1000,
  "zIndexTooltip": 10000,
  "zIndexNotification": 100000,
  "borderRadiusSmall": "4px",
  "borderRadiusMedium": "6px",
  "colorBlack": "#0C141C",
  "colorBlueLightest": "#E8F5FF",
  "colorBlueLight": "#98CBFF",
  "colorBlueMid": "#036FE3",
  "colorBlueBase": "#0059C8",
  "colorBlueDark": "#0041AB",
  "blue100": "#E8F5FF",
  "blue200": "#CEECFF",
  "blue300": "#98CBFF",
  "blue400": "#40A0FF",
  "blue500": "#036FE3",
  "blue600": "#0059C8",
  "blue700": "#0041AB",
  "blue800": "#003298",
  "blue900": "#002A8E",
  "colorContrastDark": "#0C141C",
  "colorContrastMid": "#111B2B",
  "colorContrastLight": "#1B273A",
  "colorCoralDark": "#FFE0E0",
  "colorCoralMid": "#FFF2F2",
  "colorElementDarkest": "#67728A",
  "colorElementDark": "#AEC1CC",
  "colorElementMid": "#CFD9E0",
  "colorElementLight": "#E7EBEE",
  "colorElementLightest": "#F7F9FA",
  "gray100": "#F7F9FA",
  "gray200": "#E7EBEE",
  "gray300": "#CFD9E0",
  "gray400": "#AEC1CC",
  "gray500": "#67728A",
  "gray600": "#5A657C",
  "gray700": "#414D63",
  "gray800": "#1B273A",
  "gray900": "#111B2B",
  "colorGreenDark": "#00550C",
  "colorGreenBase": "#006D23",
  "colorGreenMid": "#008539",
  "colorGreenLight": "#9ED696",
  "colorGreenLightest": "#EAF9E8",
  "green100": "#EAF9E8",
  "green200": "#CDF3C6",
  "green300": "#9ED696",
  "green400": "#5DB057",
  "green500": "#008539",
  "green600": "#006D23",
  "green700": "#00550C",
  "green800": "#004700",
  "green900": "#003F00",
  "colorIceDark": "#CEECFF",
  "colorIceMid": "#E8F5FF",
  "colorMintDark": "#CDF3C6",
  "colorMintMid": "#EAF9E8",
  "colorOrangeDark": "#B12D00",
  "colorOrangeBase": "#CC4500",
  "colorOrangeMid": "#F07F23",
  "colorOrangeLight": "#FDE5C0",
  "colorOrangeLightest": "#FFF2E4",
  "orange100": "#FFF2E4",
  "orange200": "#FDE5C0",
  "orange300": "#FDB882",
  "orange400": "#F07F23",
  "orange500": "#CC4500",
  "orange600": "#B12D00",
  "orange700": "#892300",
  "orange800": "#731A00",
  "orange900": "#631C00",
  "colorPeachDark": "#FDE5C0",
  "colorPeachMid": "#FFF2E4",
  "colorPurpleDark": "#5127B5",
  "colorPurpleBase": "#6C3ECF",
  "colorPurpleMid": "#8553E7",
  "colorPurpleLight": "#D1BBFF",
  "colorPurpleLightest": "#F7F2FF",
  "purple100": "#F7F2FF",
  "purple200": "#EDE3FF",
  "purple300": "#D1BBFF",
  "purple400": "#AE89FF",
  "purple500": "#8553E7",
  "purple600": "#6C3ECF",
  "purple700": "#5127B5",
  "purple800": "#3E16A4",
  "purple900": "#340E9C",
  "colorRedDark": "#990017",
  "colorRedBase": "#BD002A",
  "colorRedMid": "#DA294A",
  "colorRedLight": "#FFB1B2",
  "colorRedLightest": "#FFF2F2",
  "red100": "#FFF2F2",
  "red200": "#FFE0E0",
  "red300": "#FFB1B2",
  "red400": "#FF707D",
  "red500": "#DA294A",
  "red600": "#BD002A",
  "red700": "#990017",
  "red800": "#7F0010",
  "red900": "#72000E",
  "colorPrimary": "#0059C8",
  "colorPositive": "#006D23",
  "colorNegative": "#BD002A",
  "colorWarning": "#CC4500",
  "colorTextDark": "#111B2B",
  "colorTextBase": "#1B273A",
  "colorTextMid": "#414D63",
  "colorTextLight": "#5A657C",
  "colorTextLightest": "#67728A",
  "colorWhite": "#ffffff",
  "colorYellowDark": "#956300",
  "colorYellowBase": "#EAAF09",
  "colorYellowMid": "#FFC835",
  "colorYellowLight": "#FFD960",
  "colorYellowLightest": "#FFF6CC",
  "yellow100": "#FEF9DF",
  "yellow200": "#FFF6CC",
  "yellow300": "#FFE993",
  "yellow400": "#FFD960",
  "yellow500": "#FFC835",
  "yellow600": "#EAAF09",
  "yellow700": "#B78300",
  "yellow800": "#956300",
  "yellow900": "#7F5200",
  "boxShadowPositive": "0px 1px 0px rgb(25, 37, 50, 0.1)",
  "boxShadowDefault": "0px 0px 0px 1px rgba(25, 37, 50, 0.1), 0px 3px 7px -3px rgba(25, 37, 50, 0.1), 0px 6px 12px -2px rgba(25, 37, 50, 0.1);",
  "boxShadowHeavy": "0px 0px 0px 1px rgba(25, 37, 50, 0.1), 0px -6px 16px -6px rgba(25, 37, 50, 0.03), 0px 8px 16px -8px rgba(25, 37, 50, 0.2), 0px 13px 27px -5px rgba(25, 37, 50, 0.15);",
  "insetBoxShadowDefault": "inset 0px 2px 0px rgba(225, 228, 232, 0.2);",
  "glowPrimary": "0px 0px 0px 3px #98CBFF",
  "glowNegative": "0px 0px 0px 3px #FFB1B2",
  "glowPositive": "0px 0px 0px 3px #9ED696",
  "glowWarning": "0px 0px 0px 3px #FDE5C0",
  "glowMuted": "0px 0px 0px 3px #E7EBEE",
  "transitionDurationShort": "0.1s",
  "transitionDurationDefault": "0.2s",
  "transitionDurationLong": "0.3s",
  "transitionEasingDefault": "ease-in-out",
  "transitionEasingCubicBezier": "cubic-bezier(0.13, 0.62, 0.11, 0.99)",
  "fontBaseDefault": "16",
  "fontSize4Xl": "3rem",
  "fontSize3Xl": "2.25rem",
  "fontSize2Xl": "1.5rem",
  "fontSizeXl": "1.25rem",
  "fontSizeXlHigh": "1.125rem",
  "fontSizeL": "1rem",
  "fontSizeLHigh": "0.875rem",
  "fontSizeM": "0.875rem",
  "fontSizeMHigh": "0.75rem",
  "fontSizeS": "0.75rem",
  "fontSizeSHigh": "0.625rem",
  "fontStackPrimary": "-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol",
  "fontStackMonospace": "SFMono-Regular, Consolas, Liberation Mono, Menlo,monospace",
  "fontWeightNormal": 400,
  "fontWeightMedium": 500,
  "fontWeightDemiBold": 600,
  "letterSpacingDefault": "0rem",
  "letterSpacingWide": "0.1rem",
  "lineHeightDefault": "1.5",
  "lineHeightCondensed": "1.25",
  "lineHeight4Xl": "3.5rem",
  "lineHeight3Xl": "3rem",
  "lineHeight2Xl": "2.25rem",
  "lineHeightXl": "2rem",
  "lineHeightXlHigh": "1.5rem",
  "lineHeightL": "1.5rem",
  "lineHeightLHigh": "1.25rem",
  "lineHeightM": "1.25rem",
  "lineHeightMHigh": "1rem",
  "lineHeightS": "1rem",
  "lineHeightSHigh": "0.75rem"
}
    